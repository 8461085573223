<template>
  <view-item title="采购订单"></view-item>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {}
  },

  mounted() {}
})
</script>
